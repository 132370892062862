<template>
  <v-col cols="12">
    <v-row class="mb-4">
      <v-col cols="12" sm="12" md="12">
        <span class="documents-title">{{ $t('passport') }} *</span>
        <uploader ref="passportUploader" param-name="documents[0]" :url-configuration="urlConfiguration" @files-added="updateIdsList" type="ids" />
      </v-col>
      <v-col cols="12" sm="12" md="12">
        <span class="documents-title">{{ $t('itin') }} *</span>
        <uploader ref="itinUploader" param-name="documents[0]" :url-configuration="urlConfiguration" @files-added="updateItin" type="itin" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="text-left">
          <v-checkbox
              v-model="accept_data_processing"
              :label="$t('agree_to_process_personal_info') "
              color="#009357"
              hide-details
          />
        </div>
      </v-col>
      <v-col>
        <div class="text-right">
          <v-btn class="main-button" x-large :disabled="!valid && !uploading" :loading="uploading" color="#009357" @click="submit">{{ $t('save') }}</v-btn>
        </div>
      </v-col>
    </v-row>
  </v-col>
</template>
<script>
import Uploader from '@/components/Core/Uploader'
import { mapActions } from 'vuex'

export default {
  name: 'DocumentsTab',
  components: {
    Uploader
  },
  computed: {
    valid () {
      return this.accept_data_processing && this.documents && this.documents.length && this.itin && this.itin.length
    }
  },
  data: () => ({
    accept_data_processing: false,
    uploading: false,
    documents: [],
    itin: [],
    urlConfiguration: { entity: 'user.documents', action: 'upload' }
  }),
  async mounted () {
    await this.loadDocuments()
  },
  methods: {
    ...mapActions({
      pushMessage: 'ui/pushMessage',
      loadDocuments: 'user/loadDocuments'
    }),
    updateIdsList (files) {
      this.documents = files
    },
    updateItin (files) {
      this.itin = files
    },
    async submit () {
      this.uploading = true
      await this.$refs.passportUploader.processDropzone()
      await this.$refs.itinUploader.processDropzone()
      this.uploading = false
    }
  }
}
</script>
<style scoped>
.documents-title {
  font-size: 14px;
  color: #649187;
  text-align: left;
  display: block;
}
</style>
