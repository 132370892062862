<template>
  <v-col cols="12">
    <v-form ref="profileForm" v-model="valid" lazy-validation>
      <v-row>
        <v-col cols="12" sm="6" md="6">
          <v-text-field v-model="formData.first_name" :rules="[rules.required]" :label="$t('firstname_field')" maxlength="20" required outlined />
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <v-text-field v-model="formData.last_name" :rules="[rules.required]" :label="$t('lastname_field')" maxlength="20" required outlined />
        </v-col>
        <v-col cols="12">
          <v-text-field v-model="formData.email" :rules="rules.email" :label="$t('email_field')" required outlined />
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <v-select v-model="formData.country"
                    :items="countries"
                    :rules="[rules.required]"
                    item-text="label"
                    item-value="value"
                    :label="$t('country_field')"
                    persistent-hint
                    single-line
                    required
                    outlined
          />
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <v-select v-model="formData.region"
                    :items="selectedCountry.regions"
                    :rules="[rules.required]"
                    item-text="label"
                    item-value="value"
                    :label="$t('region_field')"
                    persistent-hint
                    single-line
                    required
                    outlined
          />
        </v-col>
        <v-col cols="12">
          <v-text-field v-model="formData.phone_number" :rules="[rules.required]" :label="$t('phone_number_field')" required outlined />
        </v-col>
      </v-row>
    </v-form>
    <div class="text-right">
      <v-btn class="main-button" x-large :disabled="!valid" color="#009357" @click="submit">{{ $t('save') }}</v-btn>
    </div>
  </v-col>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ProfileTab',
  computed: {
    ...mapGetters({
      token: 'user/getAccessToken',
      user: 'user/getUser'
    }),
    selectedCountry () {
      return this.countries.find(r => r.value === this.formData.country)
    },
    selectedCity () {
      return this.selectedCountry.regions.find(r => r.value === this.formData.region)
    }
  },
  data () {
    return {
      valid: false,
      formData: {
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        country: 'ukraine',
        region: 'kyiv',
        city: ''
      },
      countries: [
        {
          label: 'Україна',
          value: 'ukraine',
          regions: [
            { label: 'АР Крим', value: 'crimea' },
            { label: 'Вінницька', value: 'vinnitsia' },
            { label: 'Волинська', value: 'volin' },
            { label: 'Дніпропетровська', value: 'dnipropetrovsk' },
            { label: 'Донецька', value: 'donetsk' },
            { label: 'Житомирська', value: 'zhitomir' },
            { label: 'Закарпатська', value: 'zakarpattia' },
            { label: 'Запорізька', value: 'zaporizzha' },
            { label: 'Івано-Франківська', value: 'iveno-frankivsk' },
            { label: 'Київська', value: 'kyiv' },
            { label: 'Кіровоградська', value: 'kirovograd' },
            { label: 'Луганська', value: 'lugansk' },
            { label: 'Львівська', value: 'lviv' },
            { label: 'Миколаївська', value: 'mikolaiv' },
            { label: 'Одеська', value: 'odesa' },
            { label: 'Полтавська', value: 'poltava' },
            { label: 'Рівненська', value: 'rivne' },
            { label: 'Сумська', value: 'sumi' },
            { label: 'Тернопільська', value: 'ternopil' },
            { label: 'Харківська', value: 'harkiv' },
            { label: 'Херсонська', value: 'herson' },
            { label: 'Хмельницька', value: 'khmelnitsk' },
            { label: 'Черкаська', value: 'cherkasi' },
            { label: 'Чернівецька', value: 'cernivtsi' },
            { label: 'Чернігівська', value: 'chernihiv' },
            { label: 'місто Севастополь', value: 'sevastopol_region' }
          ]
        },
        {
          label: 'Polska',
          value: 'poland',
          regions: [
            { label: 'Łódź', value: 'lodz' },
            { label: 'Świętokrzyskie', value: 'swietokrzyskie' },
            { label: 'Greater Poland', value: 'greater-poland' },
            { label: 'Kuyavian-Pomeranian', value: 'kuyavian-pomeranian' },
            { label: 'Lesser Poland', value: 'lesser-poland' },
            { label: 'Lublin', value: 'lublin' },
            { label: 'Lubusz', value: 'lubusz' },
            { label: 'Masovian', value: 'masovian' },
            { label: 'Opole', value: 'opole' },
            { label: 'Podlachian', value: 'podlachian' },
            { label: 'Pomeranian', value: 'pomeranian' },
            { label: 'Silesian', value: 'silesian' },
            { label: 'Subcarpathian', value: 'subcarpathian' },
            { label: 'Warmian-Masurian', value: 'warmian-masurian' },
            { label: 'West Pomeranian', value: 'west-pomeranian' }
          ]
        },
        {
          label: 'Deutschland',
          value: 'germany',
          regions: [
            { label: 'Baden-Württemberg', value: 'baden-wurttemberg' },
            { label: 'Bayern', value: 'bavaria' },
            { label: 'Berlin', value: 'berlin' },
            { label: 'Brandenburg', value: 'brandenburg' },
            { label: 'Bremen', value: 'bremen' },
            { label: 'Hamburg', value: 'hamburg' },
            { label: 'Hessen', value: 'hesse' },
            { label: 'Niedersachsen', value: 'lower-saxony' },
            { label: 'Mecklenburg-Vorpommern', value: 'mecklenburg-vorpommern' },
            { label: 'Nordrhein-Westfalen', value: 'north-rhine-westphalia' },
            { label: 'Rheinland-Pfalz', value: 'rhineland-palatinate' },
            { label: 'Saarland', value: 'saarland' },
            { label: 'Sachsen', value: 'saxony' },
            { label: 'Sachsen-Anhalt', value: 'saxony-anhalt' },
            { label: 'Schleswig-Holstein', value: 'schleswig-holstein' },
            { label: 'Thüringen', value: 'thuringia' }
          ]
        },
        {
          label: 'United States of America',
          value: 'usa',
          regions: [
            { label: 'Alaska', value: 'AK' },
            { label: 'Texas', value: 'TX' },
            { label: 'Alabama', value: 'AL' },
            { label: 'Arkansas', value: 'AR' },
            { label: 'Arizona', value: 'AZ' },
            { label: 'California', value: 'CA' },
            { label: 'Colorado', value: 'CO' },
            { label: 'Connecticut', value: 'CT' },
            { label: 'District of Columbia', value: 'DC' },
            { label: 'Delaware', value: 'DE' },
            { label: 'Florida', value: 'FL' },
            { label: 'Georgia', value: 'GA' },
            { label: 'Hawaii', value: 'HI' },
            { label: 'Iowa', value: 'IA' },
            { label: 'Idaho', value: 'ID' },
            { label: 'Illinois', value: 'IL' },
            { label: 'Indiana', value: 'IN' },
            { label: 'Kansas', value: 'KS' },
            { label: 'Kentucky', value: 'KY' },
            { label: 'Louisiana', value: 'LA' },
            { label: 'Massachusetts', value: 'MA' },
            { label: 'Maryland', value: 'MD' },
            { label: 'Maine', value: 'ME' },
            { label: 'Michigan', value: 'MI' },
            { label: 'Minnesota', value: 'MN' },
            { label: 'Missouri', value: 'MO' },
            { label: 'Mississippi', value: 'MS' },
            { label: 'Montana', value: 'MT' },
            { label: 'NorthCarolina', value: 'NC' },
            { label: 'NorthDakota', value: 'ND' },
            { label: 'Nebraska', value: 'NE' },
            { label: 'NewHampshire', value: 'NH' },
            { label: 'NewJersey', value: 'NJ' },
            { label: 'NewMexico', value: 'NM' },
            { label: 'Nevada', value: 'NV' },
            { label: 'NewYork', value: 'NY' },
            { label: 'Ohio', value: 'OH' },
            { label: 'Oklahoma', value: 'OK' },
            { label: 'Oregon', value: 'OR' },
            { label: 'Pennsylvania', value: 'PA' },
            { label: 'RhodeIsland', value: 'RI' },
            { label: 'SouthCarolina', value: 'SC' },
            { label: 'SouthDakota', value: 'SD' },
            { label: 'Tennessee', value: 'TN' },
            { label: 'Texas', value: 'TX' },
            { label: 'Utah', value: 'UT' },
            { label: 'Virginia', value: 'VA' },
            { label: 'Vermont', value: 'VT' },
            { label: 'Washington', value: 'WA' },
            { label: 'Wisconsin', value: 'WI' },
            { label: 'WestVirginia', value: 'WV' },
            { label: 'Wyoming', value: 'WY' }
          ]
        }
      ],
      rules: {
        required: value => !!value || this.$t('required'),
        matches: value => this.user.password === value || this.$t('password_and_confirmation_must_match'),
        min: v => (v && v.length >= 8) || this.$t('min_characters', { number: 8 }),
        email: [
          v => !!v || this.$t('required'),
          v => /.+@.+\..+/.test(v) || this.$t('email_must_be_valid')
        ]
      }
    }
  },
  methods: {
    ...mapActions({
      updateProfileInformation: 'user/updateProfileInformation',
      pushMessage: 'ui/pushMessage'
    }),
    submit () {
      if (this.$refs.profileForm.validate()) {
        this.updateProfileInformation({
          token: this.token,
          payload: this.formData
        })
            .then(() => {
              this.pushMessage({
                status: 'success',
                message: this.$t('account_updated_message'),
                delay: 2000
              })
            })
      }
    }
  },
  mounted () {
    this.formData = this.user
  }
}
</script>
