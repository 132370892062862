<template>
  <section>
    <div>
      <vue-dropzone class="uploader-dropzone" ref="uploaderDropzone" :id="`uploader-${type}-dropzone`"
                    :options="dropzoneOptions" :useCustomSlot="true"
                    @vdropzone-files-added="filesAdded"
      >
        <div class="dropzone-custom-content">
          <v-img class="uploder-icon" width="45" src="@/assets/icons/uploader-icon.svg" />
          <h3 class="dropzone-custom-title">
            {{ $t('dropzone_title') }}
          </h3>
          <div class="subtitle">
            {{ $t('dropzone_subtitle') }}
          </div>
        </div>
      </vue-dropzone>
    </div>
  </section>
</template>
<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { buildServerUrl } from '@/server/request'
import { mapGetters } from 'vuex'

export default {
  name: 'DocumentsGallery',
  components: {
    vueDropzone: vue2Dropzone
  },
  props: {
    currentFiles: {
      type: FileList,
      required: false
    },
    type: {
      type: String,
      required: true
    },
    multiple: {
      type: Boolean,
      required: false,
      default: () => false
    },
    paramName: {
      type: String,
      required: false,
      default: () => 'file'
    },
    urlConfiguration: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      token: 'user/getAccessToken',
      xsrfToken: 'app/getXsrfToken'
    }),
    dropzoneOptions () {
      return {
        url: `${buildServerUrl(this.urlConfiguration.entity, this.urlConfiguration.action)}?type=${this.type}`,
        uploadMultiple: this.multiple,
        acceptedFiles: 'image/*',
        disablePreviews: false,
        autoProcessQueue: false,
        addRemoveLinks: true,
        paramName: this.paramName,
        headers: {
          Authorization: `Bearer ${this.token}`,
          'X-XSRF-TOKEN': this.xsrfToken
        }
      }
    }
  },
  methods: {
    filesAdded (files) {
      this.$emit('files-added', files)
    },
    processDropzone() {
      this.$refs.uploaderDropzone.processQueue()
    }
  }
}
</script>
<style scoped>
.uploader-dropzone {
  position: relative;
  margin-top: 10px;
  width: 100%;
}

.dropzone-custom-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
}

.uploder-icon {
  display: block;
  margin: 0 auto 20px;
}

.dropzone-custom-title {
  margin-top: 0;
  color: #009357;
}

.dropzone-custom-title, .subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
}

.subtitle {
  color: #649187;
}

.grid {
  display: grid;
  grid-template-columns: repeat(4, 145px);
  grid-template-rows: auto;
  grid-gap: 0.2em;
}

.grid-move {
  transition: all 0.3s;
}
</style>
<style>
.vue-dropzone {
  border: 1px solid #BDE5DD;
  border-radius: 10px;
  font-family: Arial,sans-serif;
  letter-spacing: .2px;
  transition: .2s linear;
}
</style>
