<template>
  <v-container>
    <v-row class="text-center">
      <tabs />
    </v-row>
  </v-container>
</template>
<script>
import Tabs from '@/components/User/ProfileSettings/Tabs'

export default {
  name: 'ProfileSettings',
  components: {
    Tabs
  },
  beforeMount () {
    document.title = 'Grower Exchange | Налаштування'
  },
}
</script>
