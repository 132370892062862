<template>
  <v-col cols="12">
    <v-form ref="changePasswordForm" v-model="valid" lazy-validation>
      <v-row>
        <v-col cols="12">
          <v-text-field v-model="formData.current_password"
                        :append-icon="showCurrentPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[rules.required, rules.min]"
                        :type="showCurrentPassword ? 'text' : 'password'"
                        name="password"
                        :label="$t('current_password_field')"
                        :hint="$t('min_characters', { number: 8 })"
                        @click:append="showCurrentPassword = !showCurrentPassword"
                        outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field v-model="formData.new_password"
                        :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[rules.required, rules.min]"
                        :type="showNewPassword ? 'text' : 'password'"
                        name="password"
                        :label="$t('new_password_field')"
                        :hint="$t('min_characters', { number: 8 })"
                        @click:append="showNewPassword = !showNewPassword"
                        outlined
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field v-model="formData.new_password_confirmation"
                        :append-icon="showPasswordConfirmation ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[rules.required, rules.matches]"
                        :type="showPasswordConfirmation ? 'text' : 'password'"
                        name="password-confirmation"
                        :label="$t('confirm_password_field')"
                        @click:append="showPasswordConfirmation = !showPasswordConfirmation"
                        block
                        outlined
          ></v-text-field>
        </v-col>
      </v-row>
    </v-form>
    <div class="text-right">
      <v-btn class="main-button" x-large :disabled="!valid" color="#009357" @click="submit">{{ $t('save') }}</v-btn>
    </div>
  </v-col>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'SecurityTab',
  computed: {
    ...mapGetters({
      token: 'user/getAccessToken',
      user: 'user/getUser'
    })
  },
  data () {
    return {
      valid: false,
      showCurrentPassword: false,
      showNewPassword: false,
      showPasswordConfirmation: false,
      formData: {
        current_password: '',
        new_password: '',
        new_password_confirmation: ''
      },
      rules: {
        required: value => !!value || this.$t('required'),
        matches: value => this.formData.new_password === value || this.$t('password_and_confirmation_must_match'),
        min: v => (v && v.length >= 8) || this.$t('min_characters', { number: 8 }),
      }
    }
  },
  methods: {
    ...mapActions({
      changePassword: 'user/changePassword',
      pushMessage: 'ui/pushMessage'
    }),
    submit () {
      if (this.$refs.changePasswordForm.validate()) {
        this.changePassword({
          token: this.token,
          payload: this.formData
        })
            .then(result => {
              this.pushMessage({
                status: result.status,
                message: result.message,
                delay: 2000
              })
              this.$router.push('/me/office')
            })
      }
    }
  }
}
</script>
