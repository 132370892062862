<template>
  <v-tabs v-model="tab" icons-and-text>
    <v-tab v-for="(tab, i) in tabs" :key="i">
      <v-icon large>{{ tab.icon }}</v-icon>
      <div class="caption py-1">{{ tab.name }}</div>
    </v-tab>
    <v-tab-item>
      <div class="tab-item-sizing">
        <profile />
      </div>
    </v-tab-item>
    <v-tab-item>
      <div class="tab-item-sizing">
        <documents />
      </div>
    </v-tab-item>
    <v-tab-item>
      <div class="tab-item-sizing">
        <security />
      </div>
    </v-tab-item>
  </v-tabs>
</template>
<script>
import Profile from '@/components/User/ProfileSettings/Tabs/Profile'
import Documents from '@/components/User/ProfileSettings/Tabs/Documents'
import Security from '@/components/User/ProfileSettings/Tabs/Security'

export default {
  name: 'ProfileSettingsTabs',
  components: {
    Profile,
    Documents,
    Security
  },
  beforeMount () {
    if (this.selected) {
      this.tab = this.tabs.findIndex(t => t.code === this.selected) || 0
    }
  },
  data: () => ({
    tab: 0
  }),
  computed: {
    tabs () {
      return [
        { name: this.$t('profile_tab_title'), code: 'profile', icon: 'mdi-newspaper-variant' },
        { name: this.$t('documents_tab_title'), code: 'documents', icon: 'mdi-file-sign' },
        { name: this.$t('security_tab_title'), code: 'security', icon: 'mdi-shield-account' }
      ]
    },
    selected () {
      if (this.$route.hash) {
        return this.$route.hash.replace('#', '')
      }
      return false
    }
  }
}
</script>
<style scoped>
.tab-item-sizing {
  padding: 10px 15px;
}
</style>
